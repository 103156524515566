<template>
    <div class="col-12">
        <line-item class="row keyval no-gutters">
            <div class="col-12">
                <div class="keyval__line row no-gutters">
                    <div class="keyval__key col-12 col-md-2 block-xs--sm-bottom block-md--no-bottom">{{ prop }}</div>
                    <div class="col-12 col-md-10"><slot/></div>
                </div>
            </div>
        </line-item>
    </div>
</template>

<script>
import lineItem from './line_item.vue'

export default {
    name: 'KeyValLine',
    components: {
        lineItem,
    },
    props: {
        prop: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.keyval {
    min-height: height(12);
    align-items: center;
    padding: spacing(sm);

    &__key {
        @include roboto-bold;
    }
}
</style>
