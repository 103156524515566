<template>
    <div 
        v-if="modGroup" 
        id="store-mod-group-detail" 
        class="store-mod-group-detail row no-gutters">
        <context-title 
            :title="title" 
            :passedActions="titleBarActions" 
            class="col-12" />
        <key-val-line prop="Enabled">
            {{ modGroup.enabled ? 'Yes' : 'No' }}
        </key-val-line>
        <key-val-line prop="Name">
            {{ modGroup.title }}
        </key-val-line>
        <key-val-line 
            v-if="mods.length" 
            prop="Mods">
            <div 
                v-for="mod in mods" 
                class="row">
                <div class="col-6 col-md-5 col-lg-4">{{ mod.title }}</div>
                <div class="col-6 col-md-5 col-lg-4">{{ mod.id }}</div>
            </div>
        </key-val-line>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import contextTitle from 'components/context_title.vue'
import keyValLine from 'components/key_val_line.vue'

export default {
    name: 'StoreModGroup',
    data() {
        return {
            titleBarActions: [
                {
                    type: 'edit',
                    display: 'Edit',
                    run: () => {
                        this.$router.push({
                            name: 'store-mod-group-edit',
                            params: {group: this.modGroup.id},
                        })
                    },
                },
            ],
        }
    },
    computed: {
        modGroup() {
            return this.modGroups().filter(group => group.id === this.$route.params.group)[0]
        },
        title() {
            return [
                {to: {name: 'store-mod-index'}, label: 'Mod Group'},
                this.modGroup.title,
            ]
        },
        mods() {
            return this.allMods().filter(mod => mod.modGroupId === this.$route.params.group)
        },
        ...mapGetters({
            modGroups: 'modGroups',
            allMods: 'mods',
        }),
    },
    components: {
        contextTitle,
        keyValLine,
    },
}
</script>

<style lang="scss">
</style>
