var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c("line-item", { staticClass: "row keyval no-gutters" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "keyval__line row no-gutters" }, [
            _c(
              "div",
              {
                staticClass:
                  "keyval__key col-12 col-md-2 block-xs--sm-bottom block-md--no-bottom",
              },
              [_vm._v(_vm._s(_vm.prop))]
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-10" },
              [_vm._t("default")],
              2
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }