var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modGroup
    ? _c(
        "div",
        {
          staticClass: "store-mod-group-detail row no-gutters",
          attrs: { id: "store-mod-group-detail" },
        },
        [
          _c("context-title", {
            staticClass: "col-12",
            attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
          }),
          _c("key-val-line", { attrs: { prop: "Enabled" } }, [
            _vm._v(" " + _vm._s(_vm.modGroup.enabled ? "Yes" : "No") + " "),
          ]),
          _c("key-val-line", { attrs: { prop: "Name" } }, [
            _vm._v(" " + _vm._s(_vm.modGroup.title) + " "),
          ]),
          _vm.mods.length
            ? _c(
                "key-val-line",
                { attrs: { prop: "Mods" } },
                _vm._l(_vm.mods, function (mod) {
                  return _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6 col-md-5 col-lg-4" }, [
                      _vm._v(_vm._s(mod.title)),
                    ]),
                    _c("div", { staticClass: "col-6 col-md-5 col-lg-4" }, [
                      _vm._v(_vm._s(mod.id)),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }